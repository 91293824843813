import React from 'react';
import Routing from './Routing/Routing';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

export default function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter style={{ position: 'relative' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Routing />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              theme="colored"
              pauseOnHover
            />
          </LocalizationProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}
