import { ROLES, UnityLeadersRoles } from '../../Helpers/Constants';

export const STATUSES_VACATIONS = {
  Approved: 1,

  HrPendingApproval: 20,
  HrRejected: 21,

  PlanswiftPendingManagerApproval: 30,
  PlanswiftRejectedByManager: 31,

  RevitPendingManagerApproval: 40,
  RevitRejectedByManager: 41,

  UnityPendingTeamManagerApproval: 50,
  UnityRejectedByTeamManager: 51,
  UnityPendingLeaderApproval: 52,
  UnityRejectedByLeader: 53,

  DigitizingPendingManagerApproval: 60,
  DigitizingRejectedByManager: 61,
};

export const PENDING_STATUSES_VACATIONS = [
  STATUSES_VACATIONS.PlanswiftPendingManagerApproval,
  STATUSES_VACATIONS.RevitPendingManagerApproval,
  STATUSES_VACATIONS.UnityPendingTeamManagerApproval,
  STATUSES_VACATIONS.UnityPendingLeaderApproval,
  STATUSES_VACATIONS.DigitizingPendingManagerApproval,
  STATUSES_VACATIONS.HrPendingApproval,
];

export const INITIAL_PENDING_STATUSES_VACATIONS = [
  STATUSES_VACATIONS.PlanswiftPendingManagerApproval,
  STATUSES_VACATIONS.RevitPendingManagerApproval,
  STATUSES_VACATIONS.UnityPendingLeaderApproval,
  STATUSES_VACATIONS.DigitizingPendingManagerApproval,
];

export const FINISHED_STATUSES_VACATIONS = [
  STATUSES_VACATIONS.PlanswiftRejectedByManager,
  STATUSES_VACATIONS.RevitRejectedByManager,
  STATUSES_VACATIONS.UnityRejectedByTeamManager,
  STATUSES_VACATIONS.UnityRejectedByLeader,
  STATUSES_VACATIONS.DigitizingRejectedByManager,
  STATUSES_VACATIONS.HrRejected,
  STATUSES_VACATIONS.Approved,
];

export const BALANCE_REQUEST_STATUSES = {
  Approved: 'approved',
  Pending: 'pending',
  Rejected: 'rejected',
};

export const getRoleInitialPendingStatuses = (roleName) => {
  const initialPendingStatuses = [...INITIAL_PENDING_STATUSES_VACATIONS];
  if (UnityLeadersRoles.includes(roleName)) {
    initialPendingStatuses.push(
      STATUSES_VACATIONS.UnityPendingTeamManagerApproval,
    );
  }
  if ([ROLES.HR].includes(roleName)) {
    initialPendingStatuses.push(STATUSES_VACATIONS.HrPendingApproval);
  }
  return initialPendingStatuses;
};

export const VACATIONTYPES = {
  Normal: {
    ID: 1,
    Name: 'normal',
    Label: 'Normal',
  },
  Urgent: {
    ID: 2,
    Name: 'urgent',
    Label: 'Urgent',
  },
  Sick: {
    ID: 3,
    Name: 'sick',
    Label: 'Sick',
  },
  Unpaid: {
    ID: 4,
    Name: 'unpaid',
    Label: 'Unpaid',
  },
};

export const RequestsTableVacationStatusesConstants = [
  {
    id: STATUSES_VACATIONS.Approved,
    style: {
      text: 'text-light',
      bg: 'bg-success',
    },
  },

  {
    id: STATUSES_VACATIONS.HrPendingApproval,
    style: {
      text: 'text-light',
      bg: 'bg-secondary',
    },
  },
  {
    id: STATUSES_VACATIONS.HrRejected,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },

  {
    id: STATUSES_VACATIONS.PlanswiftPendingManagerApproval,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: STATUSES_VACATIONS.PlanswiftRejectedByManager,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },

  {
    id: STATUSES_VACATIONS.RevitPendingManagerApproval,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: STATUSES_VACATIONS.RevitRejectedByManager,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },

  {
    id: STATUSES_VACATIONS.UnityPendingTeamManagerApproval,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: STATUSES_VACATIONS.UnityRejectedByTeamManager,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },
  {
    id: STATUSES_VACATIONS.UnityPendingLeaderApproval,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: STATUSES_VACATIONS.UnityRejectedByLeader,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },

  {
    id: STATUSES_VACATIONS.DigitizingPendingManagerApproval,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: STATUSES_VACATIONS.DigitizingRejectedByManager,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },
];

export const RequestsTableVacationTypesConstants = [
  {
    id: VACATIONTYPES.Normal.ID,
    style: {
      text: 'text-light',
      bg: 'bg-success',
    },
  },
  {
    id: VACATIONTYPES.Urgent.ID,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: VACATIONTYPES.Sick.ID,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },
  {
    id: VACATIONTYPES.Unpaid.ID,
    style: {
      text: 'text-light',
      bg: 'bg-secondary',
    },
  },
];
