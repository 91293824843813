import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

const ColsToHide = [
  'User ID',
  'User Email',
  'User ATL Name',
  'Backfill (hrs)',
  'Grants (hrs)',
  'Added Missed Hours (hrs)',
  'Removed Extra Hours (hrs)',
  'Performance Bonus (le)',
  'Other Dues (le)',
  'Medical Insurance (le)',
  'Loans (le)',
  'Deductions (le)',
  'Child Care Hours (hrs)',
  'Created By',
  'month',
  'half',
];

const HeaderStyle = {
  maxWidth: '50px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

// Mui datatables Utils
export const getDatatableColumns = (currentFilterFields) => {
  const datatableColumns = [
    {
      label: 'Month',
      name: 'month',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Month : ${value}`,
          update: (filterList, filterPos, index) => filterList,
        },
        filterList: [currentFilterFields.month],
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={['year', 'month']}
                    label="Month and Year"
                    openTo="month"
                    value={filterList[index][0] || null}
                    onChange={(date) => {
                      const formattedDate = date
                        ? date.format('YYYY-MM')
                        : null;
                      filterList[index][0] = formattedDate;
                      onChange(filterList[index], index, column);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'Half',
      name: 'half',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Half : ${value}`,
          update: (filterList, filterPos, index) => filterList,
        },
        filterList: [currentFilterFields.half],
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <InputLabel id="half-select-label">Half</InputLabel>
                <Select
                  labelId="half-select-label"
                  id="half-select"
                  value={filterList[index][0] || ''}
                  label="Age"
                  onChange={(e) => {
                    filterList[index][0] = e.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  <MenuItem value="first">First</MenuItem>
                  <MenuItem value="second">Second</MenuItem>
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'Name',
      label: 'Name',
      options: {
        setCellHeaderProps: () => ({ style: HeaderStyle }),
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'amount',
      label: 'amount',
      options: {
        setCellHeaderProps: () => ({ style: HeaderStyle }),
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];

  return datatableColumns;
};
export const originalToTransposed = (payrollObject) => {
  const result = [];

  for (const key in payrollObject) {
    if (Object.hasOwn(payrollObject, key) && !ColsToHide.includes(key)) {
      result.push({ Name: key, amount: payrollObject[key] });
    }
  }

  return result;
};

// Mui datatables Utils

export const createFilterFields = (currentTableState, applyFilters) => {
  // getting the fields that have value in it,
  // and getting its name from the table columns array

  const filterList = applyFilters();
  const filterFields = {};
  filterList.forEach((field, index) => {
    if (field[0]) {
      const fieldName = currentTableState.columns[index].name;
      const fieldValue = field[0];
      filterFields[fieldName] = fieldValue;
    }
  });
  return filterFields;
};
