import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import * as Requests from '../../../Helpers/Requests';
import { Pages } from '../../../Helpers/Constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  TimeFields,
  handleOvertimeInputFieldChange,
  overtimeManageDateFields,
  overtimeManageFormFields,
} from '../helpers/Constants';
import { handleDateFieldsChange } from '../../../Helpers/Helpers';
import dayjs from 'dayjs';
import { convertApprovedDurationToDecimal } from '../helpers/utils';
import { toast } from 'react-toastify';
export default function OvertimeManageApprove({
  onClose,
  selectedValue,
  open,
  renderedOvertimeRequest,
}) {
  const [isEditFormFieldsLoaded, setIsEditFormFieldsLoaded] =
    React.useState(false);

  const [timeFieldsData, setTimeFieldsData] = React.useState({});

  const [noSpecifiedDuration, setNoSpecifiedDuration] = React.useState(false);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const [editRequestFormValues, setEditRequestFormValues] = React.useState({});

  const handleClose = () => {
    onClose(selectedValue);

    // Reset component status
    setEditRequestFormValues(getFormInitialValues());
    setInvalidDateValue(false);
    setNoSpecifiedDuration(false);
    setInvalidDateValue(false);
  };

  const getFormInitialValues = () => {
    return {
      ...renderedOvertimeRequest,
      [TimeFields.ApprovedHours.Name]:
        renderedOvertimeRequest[TimeFields.RequestedHours.Name],
      [TimeFields.ApprovedMinutes.Name]:
        renderedOvertimeRequest[TimeFields.RequestedMinutes.Name],
      [overtimeManageFormFields.ApprovedDay.Name]:
        renderedOvertimeRequest[overtimeManageFormFields.RequestedDay.Name],
      [overtimeManageFormFields.ApprovedDuration.Name]:
        convertApprovedDurationToDecimal({
          [TimeFields.ApprovedHours.Name]:
            renderedOvertimeRequest[TimeFields.RequestedHours.Name],
          [TimeFields.ApprovedMinutes.Name]:
            renderedOvertimeRequest[TimeFields.RequestedMinutes.Name],
        }),
    };
  };

  React.useEffect(() => {
    setEditRequestFormValues(getFormInitialValues());
    setIsEditFormFieldsLoaded(true);
  }, [renderedOvertimeRequest]);

  React.useEffect(() => {
    if (isEditFormFieldsLoaded) {
      const ApprovedDurationDecimal = convertApprovedDurationToDecimal(
        editRequestFormValues,
      );

      setNoSpecifiedDuration(!ApprovedDurationDecimal);

      setEditRequestFormValues({
        ...editRequestFormValues,
        [overtimeManageFormFields.ApprovedDuration.Name]:
          ApprovedDurationDecimal,
      });
    }
  }, [timeFieldsData]);

  const handleEditRequestSubmit = (e) => {
    setRequestLoading(true);

    e.preventDefault();

    const formData = { ...editRequestFormValues };

    Requests.formRequest(
      `overtime/${renderedOvertimeRequest.id}/approve`,
      'put',
      formData,
    )
      .then(() => {
        toast('Extra Hours Approved successfully.', { type: 'success' });
        setTimeout(() => {
          window.location.replace(Pages.OvertimeManage.path());
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <form onSubmit={handleEditRequestSubmit} style={{ width: '500px' }}>
        <DialogTitle>Approve Extra Hours Request</DialogTitle>
        <List sx={{ pt: 0 }}>
          <DialogContent>
            <div className="d-flex">
              <div
                className="me-3 d-flex align-items-center"
                style={{ width: '30%' }}
              >
                {overtimeManageFormFields.RequestedDay.Title} :
              </div>
              <div className="d-flex w-100">
                {dayjs(
                  editRequestFormValues[
                    overtimeManageFormFields.RequestedDay.Name
                  ],
                ).format('DD-MM-YYYY')}
              </div>
            </div>
            <div className="d-flex mt-4">
              <div
                className="me-3 d-flex align-items-center"
                style={{ width: '30%' }}
              >
                {overtimeManageFormFields.RequestedDuration.Title} :
              </div>
              <div className="d-flex w-100">
                {
                  editRequestFormValues[
                    overtimeManageFormFields.RequestedDuration.Name
                  ]
                }
              </div>
            </div>
            <div className="d-flex mt-4">
              <div
                className="me-3 d-flex align-items-center"
                style={{ width: '30%' }}
              >
                {overtimeManageFormFields.ApprovedDay.Title} :
              </div>
              <div className="d-flex w-100">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={overtimeManageFormFields.ApprovedDay.PlaceHolder}
                    value={
                      editRequestFormValues[
                        overtimeManageFormFields.ApprovedDay.Name
                      ]
                    }
                    onChange={(date) =>
                      handleDateFieldsChange(
                        date,
                        overtimeManageFormFields.ApprovedDay.Name,
                        overtimeManageDateFields,
                        setInvalidDateValue,
                        editRequestFormValues,
                        setEditRequestFormValues,
                      )
                    }
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="d-flex mt-4">
              <div
                className="me-3 d-flex align-items-center"
                style={{ width: '30%' }}
              >
                {overtimeManageFormFields.ApprovedDuration.Title} :
              </div>
              <div className="d-flex w-100">
                <TextField
                  label={TimeFields.ApprovedHours.Title}
                  value={editRequestFormValues[TimeFields.ApprovedHours.Name]}
                  className="me-3"
                  style={{ width: '3.3rem' }}
                  onChange={(e) =>
                    handleOvertimeInputFieldChange(
                      e,
                      TimeFields.ApprovedHours.Name,
                      timeFieldsData,
                      setTimeFieldsData,
                      editRequestFormValues,
                      setEditRequestFormValues,
                    )
                  }
                ></TextField>
                <div className="d-flex align-items-center h5">{` : `}</div>
                <TextField
                  label={TimeFields.ApprovedMinutes.Title}
                  value={editRequestFormValues[TimeFields.ApprovedMinutes.Name]}
                  onChange={(e) =>
                    handleOvertimeInputFieldChange(
                      e,
                      TimeFields.ApprovedMinutes.Name,
                      timeFieldsData,
                      setTimeFieldsData,
                      editRequestFormValues,
                      setEditRequestFormValues,
                    )
                  }
                  className="ms-3"
                  style={{ width: '3.5rem' }}
                ></TextField>
              </div>
            </div>
            <div className="d-flex mt-4">
              <div
                className="me-3 d-flex align-items-center"
                style={{ width: '30%' }}
              >
                {overtimeManageFormFields.Comment.Title} :
              </div>
              <div className="d-flex w-100">
                <TextField
                  key={overtimeManageFormFields.Comment.Name}
                  label={overtimeManageFormFields.Comment.Title}
                  name={overtimeManageFormFields.Comment.Name}
                  defaultValue={
                    renderedOvertimeRequest[
                      overtimeManageFormFields.Comment.Name
                    ]
                  }
                  onChange={(e) =>
                    handleOvertimeInputFieldChange(
                      e,
                      overtimeManageFormFields.Comment.Name,
                      timeFieldsData,
                      setTimeFieldsData,
                      editRequestFormValues,
                      setEditRequestFormValues,
                    )
                  }
                  multiline
                  rows={4}
                  fullWidth
                  margin="dense"
                />
              </div>
            </div>
          </DialogContent>
        </List>
        <DialogActions className="d-flex justify-content-between">
          <Button onClick={handleClose}>Close</Button>
          <button
            type="submit"
            className="btn btn-success me-3"
            disabled={noSpecifiedDuration || invalidDateValue || requestLoading}
          >
            Confirm Approval
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
