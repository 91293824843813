import * as Constants from '../../../Helpers/Constants';
import * as Helpers from '../../../Helpers/Helpers';
import _ from 'lodash';
import {
  FINISHED_STATUSES_OVERTIME,
  RequestsTableOvertimeStatusesConstants,
  STATUSES_OVERTIME,
  createOvertimeTypeDiv,
  getOvertimeTypeStyleClass,
} from '../helpers/Constants';

// Statuses Related Functions

export const getOvertimeStatusesByTableType = (tableType) => {
  if (tableType === Constants.TablesTypes.Pending) {
    return [STATUSES_OVERTIME.Pending];
  } else if (tableType === Constants.TablesTypes.Finished) {
    return FINISHED_STATUSES_OVERTIME;
  }
};

export const createOvertimeStatusesFilterQuery = (tableType) => {
  const statuses = getOvertimeStatusesByTableType(tableType);
  const queryString = Helpers.createStatusesQuery(statuses);
  return queryString;
};
// Statuses Related Functions

// Mui datatables Utils

export const getDatatableColumns = () => {
  const datatableColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: 'Requested Day',
      name: 'requested_day',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'requested_duration',
      label: 'Requested Duration',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      label: 'Approved Day',
      name: 'approved_day',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'approved_duration',
      label: 'Approved Duration',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'updated_at',
      label: 'Last Updated At',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return datatableColumns;
};

export const originalToStyled = (originalRequests) => {
  const renderedRequests = originalRequests.map((request) => {
    // Adding status style to the status
    const statusStyle =
      _.find(RequestsTableOvertimeStatusesConstants, {
        id: request.status.id,
      }) || {};
    request.status.style = statusStyle.style;
    request.type.class = getOvertimeTypeStyleClass(request.type.id);
    // Modify original data to required format
    request = {
      ...request,
      user: request.user.name,
      email: request.user.email,
    };

    request = splitOvertimeDecimalDurationField(request, [
      'requested_duration',
      'approved_duration',
    ]);

    return request;
  });

  return renderedRequests;
};

export const styledToMuiDatatable = (renderedRequests, actionsClickHandler) => {
  let datatableRequests = [];
  datatableRequests = renderedRequests.map((request) => {
    let _updatedRequest = {};
    _updatedRequest = {
      ...request,
      // Decimal Time to minutes and hours should be here
      status: Helpers.createStatusBadgeDiv(
        request.status.name,
        request.status.style.bg,
        request.status.style.text,
      ),
      // type viewing from the enum
      type: createOvertimeTypeDiv(request.type.id),
    };
    return _updatedRequest;
  });

  return datatableRequests;
};

export const originalToPrintable = (originalRequests) => {
  return originalRequests.map((element) => {
    return {
      id: element.id,

      'requested day': element.requested_day,
      'requested duration': element.requested_duration,
      type: element.type.name,

      reason: element.reason,
      status: element.status.name,
      comment: element.comment,

      'approved day': element.approved_day,
      'approved duration': element.approved_duration,

      'created by': element.created_by,
      'updated by': element.updated_by,
      'created at': element.created_at,
      'updated at': element.updated_at,
    };
  });
};

// Mui datatables Utils

export const createFilterFields = (currentTableState, applyFilters) => {
  // getting the fields that have value in it,
  // and getting its name from the table columns array
  const filterList = applyFilters();
  const filterFields = {};
  filterList.forEach((field, index) => {
    if (field[0]) {
      const fieldName = currentTableState.columns[index].name;
      const fieldValue = field[0];
      filterFields[fieldName] = fieldValue;
    }
  });
  return filterFields;
};

const splitOvertimeDecimalDurationField = (
  overtimeRequest,
  decimalDurationFieldNames,
) => {
  decimalDurationFieldNames.forEach((decimalDurationFieldName) => {
    if (overtimeRequest[decimalDurationFieldName]) {
      const [hours, minutes] = Helpers.splitDecimalDurationToHrsAndMins(
        overtimeRequest[decimalDurationFieldName],
      );

      // Getting new fields names
      const splitFieldName = decimalDurationFieldName.split('_');

      overtimeRequest = {
        ...overtimeRequest,
        [`${splitFieldName[0]}_hours`]: hours,
        [`${splitFieldName[0]}_minutes`]: minutes,
        [decimalDurationFieldName]: `${hours} hrs ${minutes} minutes`,
      };
    }
  });
  return overtimeRequest;
};
