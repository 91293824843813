import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { FormControl, MenuItem, TextField } from '@mui/material';
import { toast } from 'react-toastify';

import * as Requests from '../../../Helpers/Requests';
import * as Helpers from '../../../Helpers/Helpers';
import {
  TimeFields,
  handleOvertimeInputFieldChange,
  overtimeRequestDateFields,
  overtimeRequestFormFields,
  overtimeTypes,
} from '../helpers/Constants';

export default function OvertimeRequestMain() {
  const [timeFieldsData, setTimeFieldsData] = React.useState({
    [TimeFields.RequestedHours.Name]: 0,
    [TimeFields.RequestedMinutes.Name]: 0,
  });

  const [formData, setFormData] = React.useState({
    [overtimeRequestFormFields.RequestedDay.Name]: dayjs().format('YYYY-MM-DD'),
    [overtimeRequestFormFields.RequestedDuration.Name]: 0,
    [TimeFields.RequestedHours.Name]: '',
    [TimeFields.RequestedMinutes.Name]: '',
    [overtimeRequestFormFields.Reason.Name]: '',
  });
  const [noSpecifiedType, setNoSpecifiedType] = React.useState(true);
  const [noSpecifiedDuration, setNoSpecifiedDuration] = React.useState(true);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  React.useEffect(() => {
    const RequestedDurationDecimal = parseFloat(
      (
        timeFieldsData[TimeFields.RequestedHours.Name] +
        timeFieldsData[TimeFields.RequestedMinutes.Name] / 60
      ).toFixed(4),
    );

    setNoSpecifiedDuration(!RequestedDurationDecimal);

    setFormData({
      ...formData,
      [overtimeRequestFormFields.RequestedDuration.Name]:
        RequestedDurationDecimal,
    });
  }, [timeFieldsData]);

  const handleTypeChange = (e) => {
    setFormData({
      ...formData,
      type: e.target.value,
    });
    setNoSpecifiedType(!e.target.value);
  };
  const handleSubmit = (e) => {
    setRequestLoading(true);
    e.preventDefault();

    Requests.getCurrentUser()
      .then((user) => {
        Requests.requestOvertime(formData, user.id)
          .then(() => {
            toast('Extra Hours Requested Successfully.', { type: 'success' });
            setTimeout(() => {
              window.location.href = `/extra-hours`;
            }, 1000);
          })
          .catch((err) => {
            toast(err.response.data.message, { type: 'error' });
          })
          .finally(() => {
            setRequestLoading(false);
          });
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };

  return (
    <>
      <div className="h2 ms-3 mb-5 w-50 d-flex align-items-center">
        Request an Extra Hours Approval
      </div>
      <FormControl className="w-100" variant="standard">
        <div className="d-flex flex-row align-items-start justify-content-between">
          <div className="d-flex flex-column w-100">
            <div className="d-flex">
              <div className="h5 ms-3 w-25 d-flex">Extra hours type :</div>
              <TextField
                select
                label="Select Type"
                onChange={handleTypeChange}
                className="w-25"
              >
                {overtimeTypes.map((type, index) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="d-flex mt-5">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                {overtimeRequestFormFields.RequestedDay.Title} :
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={overtimeRequestFormFields.RequestedDay.PlaceHolder}
                  value={formData[overtimeRequestFormFields.RequestedDay.Name]}
                  onChange={(date) =>
                    Helpers.handleDateFieldsChange(
                      date,
                      overtimeRequestFormFields.RequestedDay.Name,
                      overtimeRequestDateFields,
                      setInvalidDateValue,
                      formData,
                      setFormData,
                    )
                  }
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="d-flex mt-5">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                {overtimeRequestFormFields.RequestedDuration.Title} :
              </div>
              <TextField
                label={TimeFields.RequestedHours.Title}
                value={formData[TimeFields.RequestedHours.Name]}
                className="me-3"
                style={{ width: '3.3rem' }}
                onChange={(e) =>
                  handleOvertimeInputFieldChange(
                    e,
                    TimeFields.RequestedHours.Name,
                    timeFieldsData,
                    setTimeFieldsData,
                    formData,
                    setFormData,
                  )
                }
              ></TextField>
              <div className="d-flex align-items-center h5">{` : `}</div>
              <TextField
                label={TimeFields.RequestedMinutes.Title}
                value={formData[TimeFields.RequestedMinutes.Name]}
                className="ms-3"
                style={{ width: '3.5rem' }}
                onChange={(e) =>
                  handleOvertimeInputFieldChange(
                    e,
                    TimeFields.RequestedMinutes.Name,
                    timeFieldsData,
                    setTimeFieldsData,
                    formData,
                    setFormData,
                  )
                }
              ></TextField>
            </div>
            <div className="d-flex mt-5">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                {overtimeRequestFormFields.Reason.Title} :
              </div>
              <TextField
                label={overtimeRequestFormFields.Reason.PlaceHolder}
                value={formData.reason}
                onChange={(e) =>
                  handleOvertimeInputFieldChange(
                    e,
                    overtimeRequestFormFields.Reason.Name,
                    timeFieldsData,
                    setTimeFieldsData,
                    formData,
                    setFormData,
                  )
                }
                className="w-25"
              ></TextField>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex align-items-center">
          <button
            type="submit"
            className="btn btn-success ms-auto me-5"
            onClick={(e) => handleSubmit(e)}
            disabled={
              noSpecifiedType ||
              noSpecifiedDuration ||
              invalidDateValue ||
              requestLoading
            }
          >
            Submit Request
          </button>
        </div>
      </FormControl>
    </>
  );
}
