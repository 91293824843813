import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MenuItem, TextField } from '@mui/material';

import * as DashboardUtils from './DashboardUtils.jsx';
import { toast } from 'react-toastify';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import * as Requests from '../../Helpers/Requests';
import * as Helpers from '../../Helpers/Helpers';

export default function DashboardEditModal(props) {
  const [holidays, setHolidays] = React.useState([]);

  const [requestLoading, setRequestLoading] = React.useState(false);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);

  const dateFields = {
    StartDate: {
      Name: 'start_date',
    },
    EndDate: {
      Name: 'end_date',
    },
  };

  React.useEffect(() => {
    // Getting holidays then weekends and create the holidays array
    // getting holidays first
    Requests.getHolidays()
      .then((nationalHolidays) => {
        // getting weekends
        Requests.getWeekends()
          .then((weekends) => {
            // convert weekends to yearly vacations
            const yearlyWeekends = Helpers.getYearlyWeekends(weekends);
            // filter weekends that overlap with holidays
            const filteredYearlyWeekends = Helpers.filterDuplicateHolidays(
              yearlyWeekends,
              nationalHolidays,
            );
            setHolidays([...nationalHolidays, ...filteredYearlyWeekends]);
          })
          .catch((err) => Requests.handleRequestErrors(err));
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, []);

  React.useEffect(() => {
    const durationDays = Helpers.getVacationsByDuration(
      props.currentVacation.start_date,
      props.currentVacation.end_date,
      props.currentVacation.vacationType.id,
      props.vacationsTypes,
    );

    if (holidays.length) {
      // getting total deducted days.
      const _totalDeductedDays = Helpers.getTotalDeductedDays(
        durationDays,
        holidays,
      );

      props.setCurrentVacation({
        ...props.currentVacation,
        total_days: _totalDeductedDays,
      });
    }
  }, [props.currentVacation.start_date, props.currentVacation.end_date]);

  const handleSubmit = (e) => {
    setRequestLoading(true);

    e.preventDefault();
    const formData = DashboardUtils.createFormData(props.currentVacation);
    const vacationId = props.currentVacation.id;
    Requests.getCurrentUser()
      .then((user) => {
        Requests.formRequest(
          `users/${user.id}/vacations/${vacationId}`,
          'put',
          formData,
        )
          .then(() => {
            props.setVacationsUpdated(!props.vacationsUpdated);
            props.setShowModal(null);
            toast('Vacation Updated Successfully.', { type: 'success' });
          })
          .catch((err) => {
            toast(err.response.data.message, {
              type: 'error',
            });
          })
          .finally(() => {
            setRequestLoading(false);
          });
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };

  const handleTypeChange = (e) => {
    props.setCurrentVacation({
      ...props.currentVacation,
      vacationType: {
        ...props.currentVacation.vacationType,
        id: e.target.value,
      },
    });
  };

  const handleReasonChange = (e) => {
    props.setCurrentVacation({
      ...props.currentVacation,
      reason: e.target.value,
    });
  };

  return (
    <>
      <Modal
        className="h-100 d-flex align-items-center justify-content-center"
        show={props.showModal === 'edit'}
        onHide={() => props.setShowModal('view')}
      >
        <div style={{ minWidth: '400px', maxWidth: '500px', width: '40vw' }}>
          <Modal.Header>
            <Modal.Title>Edit Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-horizontal remove_bg_color">
              <div className="form-group row mt-2">
                <label
                  htmlFor="vacation-type"
                  className="col-sm-3 control-label"
                >
                  type
                </label>
                <div className="col-sm-9">
                  <TextField
                    select
                    label="Select Type"
                    value={props.currentVacation.vacationType.id}
                    onChange={handleTypeChange}
                    className="w-50"
                  >
                    {props.vacationsTypes.map((vacationType, index) => (
                      <MenuItem key={vacationType.id} value={vacationType.id}>
                        {vacationType.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className="form-group row mt-4">
                <label
                  htmlFor="vacation-type"
                  className="col-sm-3 control-label"
                >
                  Reason (Optional)
                </label>
                <div className="col-sm-9">
                  <TextField
                    label="Type a Reason"
                    multiline
                    rows={2}
                    value={props.currentVacation.reason}
                    onChange={handleReasonChange}
                    className="w-75"
                  ></TextField>
                </div>
              </div>
              <div className="form-group row mt-4 mb-4">
                <label htmlFor="min-date" className="col-sm-3 control-label">
                  Duration
                </label>
                <div className="col-sm-9">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ zIndex: '9999' }}
                  >
                    <DatePicker
                      value={props.currentVacation.start_date}
                      onChange={(date) =>
                        Helpers.handleDateFieldsChange(
                          date,
                          'start_date',
                          dateFields,
                          setInvalidDateValue,
                          props.currentVacation,
                          props.setCurrentVacation,
                        )
                      }
                      inputFormat="DD-MM-YYYY"
                      label="From"
                      renderInput={(params) => <TextField {...params} />}
                      className="mb-2"
                    />
                  </LocalizationProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ zIndex: '9999' }}
                  >
                    <DatePicker
                      value={props.currentVacation.end_date}
                      onChange={(date) =>
                        Helpers.handleDateFieldsChange(
                          date,
                          'end_date',
                          dateFields,
                          setInvalidDateValue,
                          props.currentVacation,
                          props.setCurrentVacation,
                        )
                      }
                      inputFormat="DD-MM-YYYY"
                      label="To"
                      renderInput={(params) => <TextField {...params} />}
                      className="my-2"
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="form-group row mb-2">
                <label
                  htmlFor="vacation-type"
                  className="col-sm-3 control-label"
                >
                  Total Days
                </label>
                <div className="col-sm-9">
                  {props.currentVacation.total_days}
                  {' Days'}
                </div>
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => props.setShowModal('view')}
            >
              Close
            </Button>
            <Button
              variant="success"
              onClick={(e) => handleSubmit(e)}
              disabled={requestLoading || invalidDateValue}
            >
              Confirm Edit
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
