import { FormControl, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { getPaymentReport } from '../../../Helpers/Requests';
import { toast } from 'react-toastify';
import { downloadFile } from '../../../Helpers/Helpers';
import InvoicePublish from './publish';

export default function PaymentReportPage() {
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [publishModalOpen, setPublishModalOpen] = React.useState(false);
  const [invoiceFilterValues, setInvoiceFilterValues] = useState({
    month: dayjs().startOf('month'),
    half: 'first',
  });

  const handleReviewClick = () => {
    setRequestLoading(true);

    const formData = {
      ...invoiceFilterValues,
      month: invoiceFilterValues.month.format('YYYY-MM'),
    };

    getPaymentReport(formData)
      .then((res) => {
        downloadFile(
          res,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'xlsx',
        );
      })
      .catch((err) => {
        const decoder = new TextDecoder('utf-8');
        const errData = JSON.parse(decoder.decode(err.response.data));
        toast(errData.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <>
      <InvoicePublish
        publishModalOpen={publishModalOpen}
        setPublishModalOpen={setPublishModalOpen}
        invoiceFilterValues={invoiceFilterValues}
      />

      <div className="h2 ms-3 mb-5 w-50 d-flex align-items-center">
        Payroll Invoice
      </div>
      <FormControl className="w-100" variant="standard">
        <div className="d-flex flex-row align-items-start justify-content-between">
          <div className="d-flex flex-column w-100">
            <div className="d-flex">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                Select Invoice Month :
              </div>
              <DatePicker
                label="First Payment Due Month"
                views={['year', 'month']}
                openTo="month"
                className="w-25"
                renderInput={(params) => <TextField {...params} fullWidth />}
                value={invoiceFilterValues.month}
                onChange={(date) =>
                  setInvoiceFilterValues({
                    ...invoiceFilterValues,
                    month: dayjs(date).startOf('month'),
                  })
                }
              />
            </div>
            <div className="d-flex mt-5">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                Select Month Half :
              </div>
              <TextField
                select
                label="Select Month Half"
                className="w-25"
                value={invoiceFilterValues.half}
                onChange={(e) =>
                  setInvoiceFilterValues({
                    ...invoiceFilterValues,
                    half: e.target.value,
                  })
                }
              >
                <MenuItem value={'first'}>First half of the Month</MenuItem>
                <MenuItem value={'second'}>Second half of the Month</MenuItem>
              </TextField>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-end mt-5">
          <button
            className="btn btn-primary me-5"
            onClick={handleReviewClick}
            disabled={requestLoading}
          >
            Review Invoice
          </button>
          <button
            className="btn btn-warning me-5"
            onClick={() => setPublishModalOpen(true)}
            disabled={requestLoading}
          >
            Publish Invoice
          </button>
        </div>
      </FormControl>
    </>
  );
}
