import React from 'react';
import { NavLink } from 'react-router-dom';
import { can, userHasAnyRole } from '../../../Helpers/Helpers';
import { MenuItem, SubMenu } from 'react-pro-sidebar';

export const renderNavItems = (items, CurrentSubmenusIds) => {
  const navItems = items.map((item) => {
    let menuItem;

    if (item.MenuItem) {
      const { title, path, permissions } = item.MenuItem;
      if (item.MenuItem.roles) {
        if (userHasAnyRole(item.MenuItem.roles)) {
          menuItem = (
            <MenuItem key={path} component={<NavLink to={path} end />}>
              {title}
            </MenuItem>
          );
        }
      } else {
        if (can(permissions)) {
          menuItem = (
            <MenuItem key={path} component={<NavLink to={path} end />}>
              {title}
            </MenuItem>
          );
        }
      }
    } else if (item.SubMenu) {
      const { id, title, elements } = item.SubMenu;

      // Recursively render all submenus of the current submenu
      const subNavItems = renderNavItems(elements, CurrentSubmenusIds);
      if (subNavItems.length) {
        menuItem = (
          <SubMenu
            key={id}
            label={title}
            defaultOpen={CurrentSubmenusIds?.includes(id)}
          >
            {subNavItems}
          </SubMenu>
        );
      }
    }

    return menuItem;
  });

  return navItems.filter((item) => item);
};

export function findSubMenuKey(elements, currentPath, parentKeys = []) {
  for (const element of elements) {
    if (element.SubMenu) {
      const newParentKeys = [...parentKeys, element.SubMenu.id];
      if (
        element.SubMenu.elements.some(
          (subMenuElement) => subMenuElement.MenuItem?.path === currentPath,
        )
      ) {
        return newParentKeys;
      }
      const subMenuKeys = findSubMenuKey(
        element.SubMenu.elements,
        currentPath,
        newParentKeys,
      );
      if (subMenuKeys) {
        return subMenuKeys;
      }
    }
  }
  return null;
}
