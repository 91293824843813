import {
  PERMISSIONS,
  Pages,
  SystemManagersRoles,
} from '../../../Helpers/Constants';

export const MenuItemStyles = {
  button: ({ level, active, disabled }) => {
    let buttonStyle = {};

    // only apply styles on first level elements of the tree
    if (level === 0) {
      buttonStyle = {
        ...buttonStyle,
        paddingLeft: '1.7rem',
      };
    } else {
      buttonStyle = {
        ...buttonStyle,
        marginLeft: '0.35rem',
      };
    }

    return buttonStyle;
  },
};

export const SidebarMenuElements = [
  {
    MenuItem: {
      title: Pages.Dashboard.Title,
      path: Pages.Dashboard.path(),
      permissions: [],
    },
  },
  {
    MenuItem: {
      title: Pages.PayrollView.Title,
      path: Pages.PayrollView.path(),
      permissions: [],
    },
  },
  {
    SubMenu: {
      id: 'vacations',
      title: 'Vacations',
      elements: [
        {
          MenuItem: {
            title: Pages.VacationsView.Title,
            path: Pages.VacationsView.path(),
            permissions: [],
          },
        },
        {
          MenuItem: {
            title: Pages.RequestVacation.Title,
            path: Pages.RequestVacation.path(),
            permissions: [],
          },
        },
        {
          MenuItem: {
            title: Pages.Holidays.Title,
            path: Pages.Holidays.path(),
            permissions: [],
          },
        },
        {
          MenuItem: {
            title: Pages.VacationsManage.Title,
            path: Pages.VacationsManage.path(),
            permissions: [PERMISSIONS.ManageVacations],
          },
        },
      ],
    },
  },
  {
    SubMenu: {
      id: 'excuses',
      title: 'Excuses',
      elements: [
        {
          MenuItem: {
            title: Pages.ExcusesView.Title,
            path: Pages.ExcusesView.path(),
            permissions: [],
          },
        },
        {
          MenuItem: {
            title: Pages.RequestExcuse.Title,
            path: Pages.RequestExcuse.path(),
            permissions: [],
          },
        },
        {
          MenuItem: {
            title: Pages.ExcusesManage.Title,
            path: Pages.ExcusesManage.path(),
            permissions: [PERMISSIONS.ManageVacations],
          },
        },
      ],
    },
  },
  {
    SubMenu: {
      id: 'extra-hours',
      title: 'Extra Hours',
      elements: [
        {
          MenuItem: {
            title: Pages.OvertimeView.Title,
            path: Pages.OvertimeView.path(),
            permissions: [PERMISSIONS.CreateOvertime],
          },
        },
        {
          MenuItem: {
            title: Pages.OvertimeRequest.Title,
            path: Pages.OvertimeRequest.path(),
            permissions: [PERMISSIONS.CreateOvertime],
          },
        },
        {
          MenuItem: {
            title: Pages.OvertimeManage.Title,
            path: Pages.OvertimeManage.path(),
            permissions: [PERMISSIONS.ManageOvertime],
          },
        },
      ],
    },
  },
  {
    SubMenu: {
      id: 'other-payments',
      title: 'Other Payments',
      elements: [
        {
          SubMenu: {
            id: 'one-time-payments',
            title: 'One Time Payments',
            elements: [
              {
                MenuItem: {
                  title: Pages.OneTimePaymentsView.Title,
                  path: Pages.OneTimePaymentsView.path(),
                  permissions: [PERMISSIONS.ViewAnyPaymentAdjustment],
                },
              },
              {
                MenuItem: {
                  title: Pages.PaymentAdjustmentRequest.Title,
                  path: Pages.PaymentAdjustmentRequest.path(),
                  permissions: [PERMISSIONS.CreatePaymentAdjustment],
                },
              },
              {
                MenuItem: {
                  title: Pages.OneTimePaymentsRequested.Title,
                  path: Pages.OneTimePaymentsRequested.path(),
                  permissions: [PERMISSIONS.RequestForOthersPaymentAdjustment],
                },
              },
              {
                MenuItem: {
                  title: Pages.PaymentAdjustmentManage.Title,
                  path: Pages.PaymentAdjustmentManage.path(),
                  permissions: [PERMISSIONS.ManagePaymentAdjustment],
                },
              },
            ],
          },
        },
        {
          SubMenu: {
            id: 'recurring-payments',
            title: 'Recurring Payments',
            elements: [
              {
                MenuItem: {
                  title: Pages.RecurringPaymentsView.Title,
                  path: Pages.RecurringPaymentsView.path(),
                  permissions: [PERMISSIONS.ViewAnyRecurringPayments],
                },
              },
              {
                MenuItem: {
                  title: Pages.RecurringPaymentsRequest.Title,
                  path: Pages.RecurringPaymentsRequest.path(),
                  permissions: [PERMISSIONS.CreateRecurringPayments],
                },
              },
              {
                MenuItem: {
                  title: Pages.RecurringPaymentsManage.Title,
                  path: Pages.RecurringPaymentsManage.path(),
                  permissions: [PERMISSIONS.ManageRecurringPayments],
                },
              },
            ],
          },
        },
        {
          SubMenu: {
            id: 'loans',
            title: 'Loans',
            elements: [
              {
                MenuItem: {
                  title: Pages.LoansList.Title,
                  path: Pages.LoansList.path(),
                  permissions: [PERMISSIONS.ViewAnyLoans],
                },
              },
              {
                MenuItem: {
                  title: Pages.LoansRequest.Title,
                  path: Pages.LoansRequest.path(),
                  permissions: [PERMISSIONS.CreateLoans],
                },
              },
              {
                MenuItem: {
                  title: Pages.LoansManageRequests.Title,
                  path: Pages.LoansManageRequests.path(),
                  permissions: [PERMISSIONS.ManageLoans],
                },
              },
              {
                MenuItem: {
                  title: Pages.LoansManageRunning.Title,
                  path: Pages.LoansManageRunning.path(),
                  permissions: [PERMISSIONS.ManageLoans],
                },
              },
            ],
          },
        },
        {
          SubMenu: {
            id: 'child-care',
            title: 'Child Care Hours',
            elements: [
              {
                MenuItem: {
                  title: Pages.NursingScheduleRequest.Title,
                  path: Pages.NursingScheduleRequest.path(),
                  permissions: [PERMISSIONS.CreateNursingSchedule],
                },
              },
              {
                MenuItem: {
                  title: Pages.NursingScheduleRequested.Title,
                  path: Pages.NursingScheduleRequested.path(),
                  permissions: [PERMISSIONS.ViewAnyNursingSchedule],
                },
              },
              {
                MenuItem: {
                  title: Pages.NursingScheduleManage.Title,
                  path: Pages.NursingScheduleManage.path(),
                  permissions: [PERMISSIONS.ManageNursingSchedule],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    SubMenu: {
      id: 'reports',
      title: 'Reports',
      elements: [
        {
          MenuItem: {
            title: Pages.VacationsReport.Title,
            path: Pages.VacationsReport.path(),
            permissions: [PERMISSIONS.ReportVacations],
          },
        },
        {
          MenuItem: {
            title: Pages.NursingHours.Title,
            path: Pages.NursingHours.path(),
            permissions: [PERMISSIONS.ViewAnyNursingHours],
          },
        },
        {
          MenuItem: {
            title: Pages.PaymentReport.Title,
            path: Pages.PaymentReport.path(),
            permissions: [PERMISSIONS.ViewPaymentReport],
          },
        },
        {
          MenuItem: {
            title: Pages.PayrollHistory.Title,
            path: Pages.PayrollHistory.path(),
            permissions: [PERMISSIONS.ManagePayrollHistory],
          },
        },
      ],
    },
  },
  {
    SubMenu: {
      id: 'Users',
      title: 'Users',
      elements: [
        {
          MenuItem: {
            title: Pages.UsersAll.Title,
            path: Pages.UsersAll.path(),
            permissions: [PERMISSIONS.ManageUsers],
          },
        },
        {
          MenuItem: {
            title: Pages.UsersTerminated.Title,
            path: Pages.UsersTerminated.path(),
            permissions: [PERMISSIONS.ManageUsers],
          },
        },
      ],
    },
  },
  {
    SubMenu: {
      id: 'work-logs',
      title: 'Work Logs',
      elements: [
        {
          MenuItem: {
            title: Pages.WorkLogsView.Title,
            path: Pages.WorkLogsView.path(),
            permissions: [PERMISSIONS.ViewWorkLogsExtraInfo],
          },
        },
      ],
    },
  },
  {
    SubMenu: {
      id: 'setting',
      title: 'Setting',
      elements: [
        {
          MenuItem: {
            title: Pages.PaymentSetting.Title,
            path: Pages.PaymentSetting.path(),
            permissions: [PERMISSIONS.ManagePaymentReport],
          },
        },
      ],
    },
  },
  {
    SubMenu: {
      id: 'admin-actions',
      title: 'Admin Actions',
      elements: [
        {
          MenuItem: {
            title: Pages.LoginAs.Title,
            path: Pages.LoginAs.path(),
            permissions: [PERMISSIONS.LoginAsAdminActions],
          },
        },
      ],
    },
  },
];
